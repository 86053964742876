body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  justify-content: "space-between";
  display:"flex";
 
  
}

code {
  font-family: 'Inter', sans-serif;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}
/* .css-19kzrtu {
  padding: 0 !important;
} */

/* *:hover { 
  outline:1px blue solid;
}  */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(200, 200, 200, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(200, 200, 200, 1);
}



  .react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: black !important;
}

.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--weekend)
  + .react-calendar__month-view__days__day--weekend:not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: black !important;
}



